import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { IconButton } from '@mui/material'
import {
  type GridCellParams,
  type GridRenderCellParams,
} from '@mui/x-data-grid-premium'
import { EditIcon } from '@rfh-core/icons'

import {
  CustomDataGrid,
  getFileNameFromPageTitle,
  getToolbarProps,
  type IUserAccountDayLimitView,
} from 'src/common'

import { getPurchaserLimitsColumns } from '../lib'

/* eslint-disable @typescript-eslint/no-unused-vars */
type PurchaserLimitsGridProps = {
  isLoading: boolean
  refresh: () => void
  rows: IUserAccountDayLimitView[]
  totalCount: number
}

export default function PurchaserLimitsGrid({
  isLoading,
  refresh,
  rows,
  totalCount,
}: Readonly<PurchaserLimitsGridProps>): JSX.Element {
  const { i18n, t } = useTranslation()
  const history = useHistory()

  const title = useMemo(
    () => getFileNameFromPageTitle(t('common.titles.purchaserLimitsReadOnly')),
    [t]
  )

  const getRowId = useCallback(
    (row: IUserAccountDayLimitView) => row.userAccountDayLimitId,
    []
  )

  const openInkoper = useCallback(
    (params: GridCellParams<any, IUserAccountDayLimitView, any>) => {
      if (params?.row?.userAccountId === undefined) {
        return
      }

      // Navigate to the details unless the checkbox cell, delete or edit button is clicked
      if (params?.field !== 'bewerken') {
        history.push(`/dashboard/purchasers/${params.row.userAccountId}`)
      }
    },
    [history]
  )

  const columns = useMemo(() => {
    const basicColumns = getPurchaserLimitsColumns()
    basicColumns.push({
      field: 'bewerken',
      type: 'actions',
      headerName: t('common.edit'),
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      disableExport: true,
      flex: 0.5,
      renderHeader: () => (
        <EditIcon
          sx={{
            color: theme => `${theme.rfhColors.white}`,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (
        params: GridRenderCellParams<any, IUserAccountDayLimitView>
      ) => (
        <IconButton
          component={Link}
          to={`/dashboard/purchasers/${params.row.userAccountId}`}
        >
          <EditIcon
            data-testid='edit'
            sx={{
              cursor: 'pointer',
              color: theme => `${theme.rfhColors.grey[800]}`,
              width: '20px',
              height: '20px',
            }}
          />
        </IconButton>
      ),
    })
    return basicColumns
  }, [t])

  return (
    <CustomDataGrid
      columns={columns}
      getRowId={getRowId}
      gridName={'purchaserLimits'}
      gridProps={{
        export: {
          title: t('purchaser.dayLimits'),
        },
        loading: isLoading,
        rowCount: totalCount,
        onRowClick: openInkoper,
      }}
      language={i18n.language}
      rows={rows}
      toolbarProps={getToolbarProps(title)}
    />
  )
}

import {
  IBuyersCardView,
  IInkoperPlaatView,
  ILocatie,
  Vestiging,
} from 'src/common/services/client'
import { Item } from 'src/common/types'

export const containsLocation = (
  platen: IInkoperPlaatView[],
  vestiging: Vestiging
): boolean =>
  platen.some(p => p.vestigingCode.toLowerCase() === vestiging.toLowerCase())

export const getUniqueCardNumbers = (platen: IInkoperPlaatView[]): number[] => {
  const cardNumbers = platen.map(c => c.koperPlaatNummer)
  return cardNumbers.filter(
    (card, index) => cardNumbers.indexOf(card) === index
  )
}

export const hasLocation = (
  platen: IBuyersCardView[] | null | undefined,
  vestiging: Vestiging
): boolean => platen?.some(p => !!p[vestiging])

export const locationToItem = (location: ILocatie): Item<string> => ({
  name: location?.locatieOmschrijving ?? '',
  code: location?.locatieCode ?? '',
})

import { GridValueFormatterParams } from '@mui/x-data-grid-premium'

import {
  generateDefaultFilterString,
  IPurchaserFilterInput,
  ThemeConfig,
} from 'src/common'
import {
  IInkoper,
  IInkoperView,
  InkoperPlaat,
  Vestiging,
} from 'src/common/services/client'

import { SourceValues } from '../constants/constants'
import { CardRowDiff, InkoperPlaatRow } from '../types'
import { byPlateNumber } from './lib.sort'

export const getVestiging = (v: Vestiging): string => {
  if (!v) return '-'
  switch (v.toLowerCase()) {
    case Vestiging.Aalsmeer:
      return 'A'
    case Vestiging.Naaldwijk:
      return 'N'
    case Vestiging.Rijnsburg:
      return 'R'
    case Vestiging.Eelde:
      return 'E'
  }
}

export const purchaserCardsFormatter = (row: InkoperPlaat[]): string => {
  if (!row) {
    return '-'
  }
  if (row.length) {
    const content = []
    const aalsmeerPlaten = row.filter(
      p => p.vestigingCode.toLowerCase() === Vestiging.Aalsmeer
    )
    const naaldwijkPlaten = row.filter(
      p => p.vestigingCode.toLowerCase() === Vestiging.Naaldwijk
    )
    const rijnsburgPlaten = row.filter(
      p => p.vestigingCode.toLowerCase() === Vestiging.Rijnsburg
    )
    const eeldePlaten = row.filter(
      p => p.vestigingCode.toLowerCase() === Vestiging.Eelde
    )

    if (aalsmeerPlaten.length) {
      aalsmeerPlaten.sort(byPlateNumber)
      content.push(
        aalsmeerPlaten
          .map(
            plaat =>
              `${getVestiging(plaat.vestigingCode)}${plaat.koperPlaatNummer}`
          )
          .join(', ')
      )
    }
    if (naaldwijkPlaten.length) {
      naaldwijkPlaten.sort(byPlateNumber)
      content.push(
        naaldwijkPlaten
          .map(
            plaat =>
              `${getVestiging(plaat.vestigingCode)}${plaat.koperPlaatNummer}`
          )
          .join(', ')
      )
    }
    if (rijnsburgPlaten.length) {
      rijnsburgPlaten.sort(byPlateNumber)
      content.push(
        rijnsburgPlaten
          .map(
            plaat =>
              `${getVestiging(plaat.vestigingCode)}${plaat.koperPlaatNummer}`
          )
          .join(', ')
      )
    }
    if (eeldePlaten.length) {
      eeldePlaten.sort(byPlateNumber)
      content.push(
        eeldePlaten
          .map(
            plaat =>
              `${getVestiging(plaat.vestigingCode)}${plaat.koperPlaatNummer}`
          )
          .join(', ')
      )
    }

    return content.join(', ')
  }
  return '-'
}

export const isInputValid = (name: string, value: string): boolean => {
  switch (name) {
    case 'inkoperPlatenLijst':
      return /^\d{0,4}$/.test(value)
    case 'kopernummer':
    case 'koperNummer':
      return /^\d{0,6}$/.test(value)
    case 'wachtwoord':
      return /^.{0,10}$/.test(value)
    case 'zetelnummer':
      return /^\d{0,4}$/.test(value)
    default:
      return true
  }
}

export const removeExtraIInkoperViewProperties = (
  obj: IInkoperView
): IInkoper => {
  const kaleKip = { ...obj }
  delete kaleKip.blokkeerRedenAutomatisch
  delete kaleKip.blokkeerRedenCode
  delete kaleKip.blokkeerRedenOmschrijving
  delete kaleKip.blokkerenDatumTijd
  delete kaleKip.inkoperPlatenLijst
  delete kaleKip.koperNaam
  delete kaleKip.locatieOmschrijving
  delete kaleKip.vrijgaveDatumTijd
  return kaleKip
}

export const inSingleQuotes = (str: string): string => `'${str}'`

export const cleanNonDigits = (str: string): string => str.replace(/\D/g, '')

export const calcMarginRight = (language: string): number => {
  switch (language) {
    case 'de':
      return ThemeConfig.spacing.xl * 7.95
    case 'en':
      return ThemeConfig.spacing.xl * 6.9
    default:
      return ThemeConfig.spacing.xl * 8.5
  }
}

export const showStringDiffs = (
  s1: string,
  s2: string,
  limit?: number
): void => {
  const result = s1 === s2
  if (result) return

  let diffs = Math.min(limit ?? 5, s1.length, s2.length)
  s1.split('').forEach((c, i) => {
    if (diffs > 0 && c !== s2[i]) {
      console.log(`position ${i} s1='${c}' vs s2='${s2[i]}'`)
      diffs--
    }
  })
}

export const getCheckboxDiffs = (
  newRows: InkoperPlaatRow[],
  oldRows: InkoperPlaatRow[]
): CardRowDiff[] => {
  const diffs: CardRowDiff[] = []
  const addOrDelete = (checked: boolean): 'ADD' | 'DELETE' =>
    checked ? 'ADD' : 'DELETE'
  const newDiff = (row: InkoperPlaatRow): CardRowDiff => ({
    action: undefined,
    inkoperIdentificatie: row.inkoperIdentificatie,
    koperNummer: row.koperNummer,
    koperPlaatNummer: row.koperPlaatNummer,
    vestigingCode: undefined,
  })

  newRows.forEach(newRow => {
    const oldRow = oldRows[newRows.indexOf(newRow)]
    if (newRow.aalsmeer === !oldRow.aalsmeer) {
      diffs.push({
        ...newDiff(newRow),
        action: addOrDelete(newRow.aalsmeer),
        vestigingCode: Vestiging.Aalsmeer,
      })
    }
  })

  newRows.forEach(newRow => {
    const oldRow = oldRows[newRows.indexOf(newRow)]
    if (newRow.naaldwijk === !oldRow.naaldwijk) {
      diffs.push({
        ...newDiff(newRow),
        action: addOrDelete(newRow.naaldwijk),
        vestigingCode: Vestiging.Naaldwijk,
      })
    }
  })

  newRows.forEach(newRow => {
    const oldRow = oldRows[newRows.indexOf(newRow)]
    if (newRow.rijnsburg === !oldRow.rijnsburg) {
      diffs.push({
        ...newDiff(newRow),
        action: addOrDelete(newRow.rijnsburg),
        vestigingCode: Vestiging.Rijnsburg,
      })
    }
  })

  newRows.forEach(newRow => {
    const oldRow = oldRows[newRows.indexOf(newRow)]
    if (newRow.eelde === !oldRow.eelde) {
      diffs.push({
        ...newDiff(newRow),
        action: addOrDelete(newRow.eelde),
        vestigingCode: Vestiging.Eelde,
      })
    }
  })

  return diffs
}

export const isLastLocationToBeRemoved = (row: InkoperPlaatRow): boolean =>
  !(row.aalsmeer || row.naaldwijk || row.rijnsburg || row.eelde)

type SourceType = ' ' | 'Rru' | 'Floriday'
export const formatSource = (row: GridValueFormatterParams): SourceType =>
  ConvertSource(row.value, SourceValues)

function ConvertSource<Tkey extends keyof typeof SourceValues>(
  value: Tkey,
  obj: typeof SourceValues
): SourceType {
  if (value in obj) {
    return obj[value]
  } else {
    return ' '
  }
}

export const generateFilterString = (
  key: keyof IPurchaserFilterInput,
  value: IPurchaserFilterInput[keyof IPurchaserFilterInput],
  filterRules: string[]
): string[] => {
  if (key == null || value == null) {
    return filterRules
  }
  switch (key) {
    case 'inkoperIdentificatie':
      filterRules.push(
        `(contains(${key},'${value}') or contains(alias,'${value}'))`
      )
      break
    case 'inkoperPlatenLijst':
      filterRules.push(`contains(${key}, ',${value},')`)
      break
    case 'vervalIndicatie':
      filterRules.push(
        value
          ? `vervallenDatumTijd lt ${new Date().toISOString()}`
          : `vervallenDatumTijd ge ${new Date().toISOString()}`
      )
      break
    default:
      filterRules = generateDefaultFilterString(key, value as any, filterRules)
      break
  }

  return filterRules
}
